import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import LocationModal from './LocationModal';

const Manage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('UUID');

  const [locations, setLocations] = useState([
    { id: 1, name: 'Kita Sonnenschein', address: 'Sonnenallee 1, 12345 Berlin', capacity: 50, openSpots: 5 },
    { id: 2, name: 'Kita Regenbogen', address: 'Regenstraße 5, 12345 Berlin', capacity: 40, openSpots: 2 },
    { id: 3, name: 'Kita Sternchen', address: 'Sternplatz 3, 12345 Berlin', capacity: 60, openSpots: 8 },
  ]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
  };

  const handleAddLocation = () => {
    setSelectedLocation(null);
    setIsModalOpen(true);
  };

  const handleSaveLocation = (locationData) => {
    if (selectedLocation) {
      setLocations(locations.map(loc => loc.id === selectedLocation.id ? { ...loc, ...locationData } : loc));
    } else {
      setLocations([...locations, { id: Date.now(), ...locationData }]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-gray-800">{t('manage.title')}</h1>
      <p className="mb-8 text-gray-600">{t('manage.description')}</p>
      <p className="mb-8 text-gray-700 font-semibold">{t('manage.uuidInfo', { uuid })}</p>
      
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold text-gray-700">{t('manage.locationsList')}</h2>
        <button 
          onClick={handleAddLocation}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('manage.addLocation')}
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {locations.map((location) => (
          <div 
            key={location.id} 
            className="border p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer bg-white"
            onClick={() => handleLocationClick(location)}
          >
            <h3 className="font-bold text-xl mb-2 text-gray-800">{location.name}</h3>
            <p className="text-gray-600 mb-2">{location.address}</p>
            <div className="flex justify-between text-sm">
              <span className="text-blue-600">{t('manage.capacity')}: {location.capacity}</span>
              <span className="text-green-600">{t('manage.openSpots')}: {location.openSpots}</span>
            </div>
          </div>
        ))}
      </div>

      {selectedLocation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{selectedLocation.name}</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  {selectedLocation.address}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  {t('manage.capacity')}: {selectedLocation.capacity}
                </p>
                <p className="text-sm text-gray-500">
                  {t('manage.openSpots')}: {selectedLocation.openSpots}
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  onClick={() => setSelectedLocation(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <LocationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveLocation}
        initialData={selectedLocation}
      />
    </div>
  );
};

export default Manage;
